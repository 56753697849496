export function range(start: number, end: number, step: number): number[] {
  const result: number[] = []

  const precision = step.toString().split('.')[1]?.length || 0

  for (let i = start; i <= end; i = +Number(i + step).toFixed(precision)) {
    result.push(i)
  }

  return result
}

export function declination(number: number, words: string): string {
  const w = words.split('|') as [string, string, string, string]
  const n = Math.abs(number * 1)

  return n % 10 == 1 && n % 100 != 11
    ? w[0] + w[1]
    : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? w[0] + w[2]
      : w[0] + w[3]
}
